import Vue from 'vue'
import VueRouter from 'vue-router'
import httpHelper from '@/utils/request'
import { getMBankAuth, getArgument } from '@/utils/cookies'
import configs from '@/configs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'list',
    redirect: '/sms'
    // component: () => import(/* webpackChunkName: "list" */ '../views/List.vue')
  },
  {
    path: '/union',
    name: 'union',
    component: () => import(/* webpackChunkName: "union" */ '../views/union/Login.vue')
  },
  {
    path: '/union/sms',
    name: 'union-sms',
    component: () => import(/* webpackChunkName: "union" */ '../views/union/SMS.vue')
  },
  {
    path: '/secret',
    name: 'secret'
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import(/* webpackChunkName: "partner" */ '../views/Partner.vue')
  },
  {
    path: '/partner/ban',
    name: 'ban',
    component: () => import(/* webpackChunkName: "partner" */ '../views/Ban.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/loginsign',
    name: 'loginSign',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginSign.vue')
  },
  {
    path: '/sms',
    name: 'sms',
    component: () => import(/* webpackChunkName: "sms" */ '../views/SMS.vue')
  },
  {
    path: '/bind',
    name: 'bind',
    component: () => import(/* webpackChunkName: "bind" */ '../views/BindPhone.vue')
  },
  {
    path: '/combine',
    name: 'combine',
    component: () => import(/* webpackChunkName: "combine" */ '../views/CombineAccount.vue')
  },
  {
    path: '/explain',
    name: 'explain',
    component: () => import(/* webpackChunkName: "explain" */ '../views/CombineExplain.vue')
  },
  {
    path: '/find',
    name: 'find',
    component: () => import(/* webpackChunkName: "find" */ '../views/FindPassword.vue')
  },
  {
    path: '/authorization/qqlogin',
    name: 'qqlogin',
    component: () => import(/* webpackChunkName: "qqlogin" */ '../views/authorization/qqlogin.vue')
  },
  {
    path: '/authorization/wechatlogin',
    name: 'wechatlogin',
    component: () => import(/* webpackChunkName: "wechatlogin" */ '../views/authorization/wechatlogin.vue')
  },
  {
    path: '/authorization/sinalogin',
    name: 'sinalogin',
    component: () => import(/* webpackChunkName: "sinalogin" */ '../views/authorization/sinalogin.vue')
  },
  {
    path: '/authorization/alipaylogin',
    name: 'alipaylogin',
    component: () => import(/* webpackChunkName: "alipaylogin" */ '../views/authorization/alipaylogin.vue')
  },
  {
    path: '/authorization/zijinrcb',
    name: 'zijinrcb',
    component: () => import(/* webpackChunkName: "zijinrcb" */ '../views/authorization/zijinrcb.vue')
  },
  {
    path: '/authorization/maidanbalogin',
    name: 'maidanbalogin',
    component: () => import(/* webpackChunkName: "maidanbalogin" */ '../views/authorization/maidanbalogin.vue')
  },
  {
    path: '/authorization/doulilogin',
    name: 'doulilogin',
    component: () => import(/* webpackChunkName: "doulilogin" */ '../views/authorization/doulilogin.vue')
  },
  {
    path: '/authorization/icbclogin',
    name: 'icbclogin',
    component: () => import(/* webpackChunkName: "icbclogin" */ '../views/authorization/icbclogin.vue')
  },
  {
    path: '/authorization/cmblogin',
    name: 'cmblogin',
    component: () => import(/* webpackChunkName: "cmblogin" */ '../views/authorization/cmblogin.vue')
  },
  {
    path: '/authorization/snzjlogin',
    name: 'snzjlogin',
    component: () => import(/* webpackChunkName: "snzjlogin" */ '../views/authorization/snzjlogin.vue')
  },
  {
    path: '/authorization/yiqianbaologin',
    name: 'yiqianbaologin',
    component: () => import(/* webpackChunkName: "yiqianbaologin" */ '../views/authorization/yiqianbaologin.vue')
  },
  {
    path: '/authorization/yiqianbaoapplogin',
    name: 'yiqianbaoapplogin',
    component: () => import(/* webpackChunkName: "yiqianbaologin" */ '../views/authorization/yiqianbaoapplogin.vue')
  },
  {
    path: '/authorization/ccblogin',
    name: 'ccblogin',
    component: () => import(/* webpackChunkName: "ccblogin" */ '../views/authorization/ccblogin.vue')
  },
  {
    path: '/ccbnj',
    name: 'ccbnjBank',
    component: () => import(/* webpackChunkName: "ccblogin" */ '../views/authorization/ccbnjBank.vue')
  },
  {
    path: '/abnormal',
    name: 'abnormal',
    component: () => import(/* webpackChunkName: "abnormal" */ '../views/abnormal.vue')
  },
  {
    path: '/address',
    name: 'addresslist',
    component: () => import(/* webpackChunkName: "addresslist" */ '../views/address/List.vue')
  },
  {
    path: '/address/:id',
    name: 'addressedit',
    component: () => import(/* webpackChunkName: "addressedit" */ '../views/address/Edit.vue')
  },
  {
    path: '/mbank',
    name: 'mbank',
    component: () => import(/* webpackChunkName: "mbank" */ '../views/Mbank.vue')
  },
  {
    path: '/userReview',
    name: 'userReview',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/account/userReview.vue')
  },
  {
    path: '/myReviewRules',
    name: 'myReviewRules',
    component: () => import(/* webpackChunkName: "myReviewRules" */ '../views/account/myReviewRules.vue')
  },
  {
    path: '/member',
    name: 'member',
    component: () => import(/* webpackChunkName: "member" */ '../views/account/member.vue')
  },
  {
    path: '/member/growup',
    name: 'memberGrowup',
    component: () => import(/* webpackChunkName: "memberGrowup" */ '../views/account/memberGrowup.vue')
  },
  {
    path: '/member/growup/account',
    name: 'memberAccount',
    component: () => import(/* webpackChunkName: "memberAccount" */ '../views/account/memberAccount.vue')
    // component: () => import(/* webpackChunkName: "find" */ '../views/Mbank.vue')
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/order/Invoice.vue')
  },
  {
    path: '/invoiceSet',
    name: 'invoiceSet',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/order/InvoiceSet.vue'),
    meta: {
      title: '设置发票信息'
    }
  },
  {
    path: '/invoiceCenter/:id',
    name: 'invoiceCenterRoute',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/order/InvoiceCenter.vue'),
    meta: {
      title: '发票中心'
    }
  },
  {
    path: '/invoiceCenter',
    name: 'invoiceCenter',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/order/InvoiceCenter.vue'),
    meta: {
      title: '发票中心'
    }
  },
  {
    path: '/invoiceDetail/:id',
    name: 'invoiceDetail',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/order/InvoiceDetail.vue'),
    meta: {
      title: '发票详情'
    }
  },
  {
    path: '/invoiceTitle/:id/:type',
    name: 'invoiceTitle',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/order/InvoiceTitle.vue')
  },
  {
    path: '/invoiceMsg',
    name: 'invoiceMsg',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/order/InvoiceMsg.vue')
  },
  {
    path: '/email/set',
    name: 'emailSet',
    component: () => import(/* webpackChunkName: "memberGrowup" */ '../views/email/set.vue')
  },
  {
    path: '/email/edit',
    name: 'emailEdit',
    component: () => import(/* webpackChunkName: "memberGrowup" */ '../views/email/edit.vue')
  },
  {
    path: '/changePhone',
    name: 'changePhone',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/account/changePhone.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/account/changePassword.vue')
  },
  {
    path: '/modify',
    name: 'modifyPassword',
    component: () => import(/* webpackChunkName: "modify" */ '../views/ModifyPassword.vue')
  },
  {
    path: '/house/list',
    name: 'homeDeliveryList',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/house/List.vue')
  },
  {
    path: '/house/detail/:id',
    name: 'homeDeliveryDetail',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/house/Detail.vue')
  },
  {
    path: '/house/activation/:id',
    name: 'homeDeliveryActivation',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/house/Activate.vue')
  },
  // 宅配订单页地址列表
  {
    path: '/house/address/:oid',
    name: 'homeDeliveryAddress',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/house/Addresslist.vue')
  },
  {
    path: '/argument',
    name: 'argument',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/Argument.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/Argument.vue')
  },
  {
    path: '/yj',
    name: 'yj',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/yg.vue')
  },
  {
    path: '/citicbank',
    name: 'citicBank',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/authorization/CiticBank.vue')
  },
  {
    path: '/citiclife',
    name: 'citicLife',
    component: () => import(/* webpackChunkName: "userReview" */ '../views/CiticLife.vue')
  },
  {
    path: '/replaceMobile/verify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "sms" */ '../views/replaceMobile/verify.vue')
  },
  {
    path: '/jnbank',
    name: 'jnbank',
    component: () => import(/* webpackChunkName: "jnbank" */ '../views/jnbank.vue')
  },
  {
    path: '/zhchbank',
    name: 'zhchbank',
    component: () => import(/* webpackChunkName: "zhchbank" */ '../views/zhchbank.vue')
  },
  {
    path: '/ccblife',
    name: 'ccblife',
    component: () => import(/* webpackChunkName: "ccblife" */ '../views/ccblife.vue')
  },
  {
    path: '/guanaitong',
    name: 'guanaitong',
    component: () => import(/* webpackChunkName: "guanaitong" */ '../views/guanaitong.vue')
  },
  {
    path: '/bcmbank',
    name: 'bcmbank',
    component: () => import(/* webpackChunkName: "bcmbank" */ '../views/bcmbank.vue')
  },
  {
    path: '/bobbank',
    name: 'bobbank',
    component: () => import(/* webpackChunkName: "bcmbank" */ '../views/bobbank.vue')
  },
  {
    path: '/sobeirui',
    name: 'sobeirui',
    component: () => import(/* webpackChunkName: "sobeirui" */ '../views/Sobeirui.vue')
  },
  {
    path: '/sinopec',
    name: 'sinopec',
    component: () => import(/* webpackChunkName: "sinopec" */ '../views/Sinopec.vue')
  },
  {
    path: '/auth/:platform/:urlTag?',
    name: 'auth',
    component: () => import(/* webpackChunkName: "sinopec" */ '../views/PlatformAuth.vue')
  },
  {
    path: '/bestpay/auth/:channelTag',
    name: 'bestpayAuthWithChannelTag',
    component: () => import(/* webpackChunkName: "bestpay" */ '../views/AuthEntryBestpay.vue')
  },
  {
    path: '/bestpay/auth',
    name: 'bestpayAuth',
    component: () => import(/* webpackChunkName: "bestpay" */ '../views/AuthEntryBestpay.vue')
  },
  {
    path: '/b/authorize',
    name: 'authorize',
    component: () => import(/* webpackChunkName: "businessAuthorize" */ '../views/business/authorize.vue')
  },
  {
    path: '/b/phone',
    name: 'bindphone',
    component: () => import(/* webpackChunkName: "businessBindPhone" */ '../views/business/bindPhone.vue')
  },
  {
    path: '/b/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "businessError" */ '../views/business/error.vue')
  },
  {
    path: '/b/combine',
    name: 'combineAccount',
    component: () => import(/* webpackChunkName: "businessCombine" */ '../views/business/CombineAccount.vue')
  },
  {
    path: '/b/explain',
    name: 'combineAccountExplain',
    component: () => import(/* webpackChunkName: "businessExplain" */ '../views/business/CombineExplain.vue')
  },
  {
    path: '/meican',
    name: 'meican',
    component: () => import(/* webpackChunkName: "meican" */ '../views/Meican.vue')
  },
  {
    path: '/czmobile',
    name: 'czmobile',
    component: () => import(/* webpackChunkName: "czmobile" */ '../views/CZMobile.vue')
  },
  {
    path: '/liangchu',
    name: 'liangchu',
    component: () => import(/* webpackChunkName: "liangchu" */ '../views/Liangchu.vue')
  },
  {
    path: '/wxLogin',
    name: 'wechatSilentlyLogin',
    component: () => import(/* webpackChunkName: "wechatSilentlyLogin" */ '../views/WechatSilentlyLogin.vue')
  },
  {
    path: '/gdyd',
    name: 'gdyd',
    component: () => import(/* webpackChunkName: "gdyd" */ '../views/authorization/gdyd.vue')
  },
  {
    path: '/xftlogin',
    name: 'xftlogin',
    component: () => import(/* webpackChunkName: "xftlogin" */ '../views/xftlogin.vue')
  },
  {
    path: '/cxmt',
    name: 'cxmt',
    component: () => import(/* webpackChunkName: "cxmt" */ '../views/Cxmt.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach(async (to, from, next) => {
  // if (to.name === 'maidanbalogin') {
  //   if (getMBankAuth()) {
  //     location.href = encodeURI(`https://paymenttest.bankcomm.com/coweb/oautht/authorize?client_id=maidanba_to_benlaish&response_type=code&redirect_uri=` + encodeURI(`https://passport.benlai.com/authorization/maidanbalogin`) + '&source=2')
  //   } else {
  //     next()
  //   }
  //   return
  // }
  // 从www跳回的地址上运维无法去掉/passportbenlai，需要前端自己处理
  if (to.path && (to.path as string).indexOf('passportbenlai') > -1) {
    const url = to.fullPath.replace('/passportbenlai', '')
    location.replace(`${url}`)
  }
  if (to.name === 'doulilogin') {
    const douli = getMBankAuth()
    if (!douli) {
      next()
      return
    }
    let { source = '11', ...params } = to.query
    const json = encodeURIComponent(JSON.stringify(params))
    location.replace(`${location.origin}/partner?redirect=https%3A%2F%2Fdlpt-h5.benlai.com%2F&source=11&data=${json}`)
    return
  }
  if (to.name === 'icbclogin') {
    const icbc = getMBankAuth()
    if (!icbc) {
      next()
      return
    }
    let { source = '11', ...params } = to.query
    const json = encodeURIComponent(JSON.stringify(params))
    location.replace(`${location.origin}/partner?redirect=https%3A%2F%2Fszicbc-h5.benlai.com%2F&source=11&data=${json}`)
    return
  }
  if (to.name === 'cmblogin') {
    const icbc = getMBankAuth()
    if (!icbc) {
      next()
      return
    }
    let params = location.search
    location.replace(`${httpHelper.mRoot}lishe/oauth${params}`)
    return
  }
  if (to.name === 'argument' || to.name === 'agreement') {
    const argumented = getArgument()
    if (!argumented) {
      next()
      return
    }
    // let target: any = to.query.target || ''
    let { target, source = '11', ...params } = to.query
    target = target.toString()
    const json = encodeURIComponent(JSON.stringify(params))
    if (!target) {
      location.replace(`${location.origin}/partner?redirect=${encodeURIComponent(configs.h5Root)}&source=11&data=${json}`)
      return
    }
    if (/^http(s?):/.test(target)) {
      location.replace(`${location.origin}/partner?redirect=${encodeURIComponent(target)}&source=11&data=${json}`)
      return
    }
    switch (target) {
      case 'm':
        location.replace(`${location.origin}/partner?redirect=https%3A%2F%2Fm.benlai.com%2F&data=${json}`)
        break
      default:
        location.replace(`${location.origin}/partner?redirect=${encodeURIComponent(configs.h5Root)}&source=11&data=${json}`)
    }
    return
  }
  // if (to.name === 'zijinrcb') {
  //   const token = getCurrentQuery('token')
  //   if (getMBankAuth()) {
  //     location.href = 'https://passport.benlai.com/partner?redirect=' + encodeURI(`https://zjns-h5.benlai.com`) + '&source=11' + '&token=' + token
  //   } else {
  //     next()
  //   }
  //   return
  // }
  if (to.name === 'mbank') {
    if (getMBankAuth()) {
      location.replace(`${location.origin}/partner?redirect=${encodeURIComponent(configs.h5Root)}n%2F2ONOTPD6&source=11`)
    } else {
      // setMBankAuth()
      next()
    }
    return
  }
  if (to.name === 'bcmbank') {
    if (getMBankAuth()) {
      // next({
      //   path: '/jnbank?redirect=https%3A%2F%2Fh5.benlai.com%2Fn%2F2ONOTPD6&source=11',
      //   replace: true
      // })
      next()
    } else {
      // setMBankAuth()
      next()
    }
    return
  }
  if (to.name === 'jnbank') {
    next()
    return
    // if (getMBankAuth()) {
    //   location.href = encodeURI(`https://paymenttest.bankcomm.com/coweb/oautht/authorize?client_id=maidanba_to_benlaish&response_type=code&redirect_uri=` + encodeURI(`https://passport.benlai.com/authorization/maidanbalogin`) + '&source=2')
    // } else {
    //   next()
    // }

    // if (getMBankAuth()) {
    //   next({
    //     path: '/jnbank?redirect=https%3A%2F%2Fh5.benlai.com%2Fn%2F2ONOTPD6&source=11',
    //     replace: true
    //   })
    // } else {
    //   // setMBankAuth()
    //   next()
    // }
    // return
  }
  if (to.name !== 'secret') {
    next()
    return
  }

  const query: any = to.query
  const params: any = {
    customerId: query.customerId,
    signal: query.token,
    timestamp: query.t
  }
  await httpHelper.requestForBack(params)
  location.href = `${httpHelper.mRoot}`
})

export default router
