import { Base64 } from 'js-base64'

const CLIENT_ID = '12c0341e2d9a4f92b5b2cf1968160ccc'
const CLIENT_SECRET = '150e816f611a4456aa71f4b60dcf84ce'
const PRODUCTION = 'production'
const PROTOCOL = 'https://'
const DOMAIN = '.benlai.com'
export const ENV = process.env.VUE_APP_ENV || PRODUCTION

const getPrefix = () => ENV === PRODUCTION ? '' : `-${ENV}`

const getUrlConfigs = () => {
  const prefix = getPrefix()
  return {
    mRoot: 'https://m.benlai.com/',
    mcRoot: 'https://mc.benlai.com/',
    h5Root: `${PROTOCOL}h5${prefix}${DOMAIN}/`,
    lifeRoot: `${PROTOCOL}life${prefix}${DOMAIN}/`,
    unifyRoot: `${PROTOCOL}unify${prefix}${DOMAIN}/`,
    authRoot: `${PROTOCOL}auth${prefix}${DOMAIN}/`,
    appApiRoot: `${PROTOCOL}app-api${prefix}${DOMAIN}/`,
    passportHost: `passport${prefix}${DOMAIN}`,
    passportRoot: `${PROTOCOL}passport${prefix}${DOMAIN}/`,
    passportApiRoot: `${PROTOCOL}passport-api${prefix}${DOMAIN}/`
  }
}

const urlConfigs = getUrlConfigs()
const basicAuthorization = `Basic ${Base64.encode(`${CLIENT_ID}:${CLIENT_SECRET}`)}`
const version = '1.0.0'

const configs = {
  ...urlConfigs,
  basicAuthorization,
  version
}

export default configs
