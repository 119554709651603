import Cookies from 'js-cookie'

// User
const TOKEN_KEY = 'benlai_h5_access_token'
export const getToken = () => Cookies.get(TOKEN_KEY)
export const setToken = (token: string) => Cookies.set(TOKEN_KEY, token)
export const removeToken = () => Cookies.remove(TOKEN_KEY)

const UUK_KEY = 'benlai_h5_uuk'
export const getUUK = () => Cookies.get(UUK_KEY)
export const setUUK = (uuk: string) => Cookies.set(UUK_KEY, uuk)
export const removeUUK = () => Cookies.remove(UUK_KEY)

const AUTH_KEY = 'benlai_h5_auth'
export const getAuth = () => Cookies.get(AUTH_KEY)
export const setAuth = (auth: any) => Cookies.set(AUTH_KEY, auth)
export const removeAuth = () => Cookies.remove(AUTH_KEY)

const ADDRESS_KEY = 'benlai_h5_address'
export const getSelectedAddress = () => parseInt(Cookies.get(ADDRESS_KEY)) || 0
export const setSelectedAddress = (address: number) => Cookies.set(ADDRESS_KEY, address)
export const removeSelectedAddress = () => Cookies.remove(ADDRESS_KEY)

const MBANK_AUTH_KEY = 'benlai_h5_mbank'
export const getMBankAuth = () => !!Cookies.get(MBANK_AUTH_KEY)
export const setMBankAuth = () => Cookies.set(MBANK_AUTH_KEY, '1')

const HOUSE_ADDRESS_KEY = 'benlai_house_address'
export const getSelectedHouseAddress = () => Cookies.get(ADDRESS_KEY)
export const setSelectedHouseAddress = (address: any) => Cookies.set(ADDRESS_KEY, address)
export const removeSelectedHouseAddress = () => Cookies.remove(HOUSE_ADDRESS_KEY)

const ARGUMENT_KEY = 'benlai_h5_argument'
export const getArgument = () => !!Cookies.get(ARGUMENT_KEY)
export const setArgument = () => Cookies.set(ARGUMENT_KEY, '1')

const cookieOption = {
  path: '/',
  domain: '.benlai.com'
}

const BENLAI_H5_YIQIANBAO_AUTHCODE_KEY = 'benlai_h5_yiqianbao_authCode'
export const getYiqianbaoAuthCode = () => Cookies.get(BENLAI_H5_YIQIANBAO_AUTHCODE_KEY)
export const setYiqianbaoAuthCode = (auth: String) => Cookies.set(BENLAI_H5_YIQIANBAO_AUTHCODE_KEY, auth, cookieOption)

const BENLAI_H5_YIQIANBAO_SSCEXT_KEY = 'benlai_h5_yiqianbao_sscExt'
export const getYiqianbaoSscExt = () => Cookies.get(BENLAI_H5_YIQIANBAO_SSCEXT_KEY)
export const setYiqianbaoSscExt = (auth: string) => Cookies.set(BENLAI_H5_YIQIANBAO_SSCEXT_KEY, decodeURIComponent(auth), cookieOption)

const BENLAI_H5_YIQIANBAO_SOURCE_KEY = 'benlai_h5_yiqianbao_source'
export const getYiqianbaoSource = () => Cookies.get(BENLAI_H5_YIQIANBAO_SOURCE_KEY)
export const setYiqianbaoSource = (auth: String) => Cookies.set(BENLAI_H5_YIQIANBAO_SOURCE_KEY, auth, cookieOption)

const ABC_USER = 'benlai_h5_abcuser'
export const getABCUser = () => Cookies.get(ABC_USER)
export const setABCUser = (user: string) => Cookies.set(ABC_USER, user, cookieOption)
export const removeABCUser = () => Cookies.remove(ABC_USER, cookieOption)

const CCB_ID = 'benlai_h5_ccbid'
export const getCCBId = () => Cookies.get(CCB_ID)
export const setCCBId = (user: string) => Cookies.set(CCB_ID, user, cookieOption)

const CCB_SID = 'benlai_h5_ccbschoolid'
export const getCCBSchoolId = () => Cookies.get(CCB_SID)
export const setCCBSchoolId = (user: string) => Cookies.set(CCB_SID, user, cookieOption)

export const setCountYqb = (s:string) => Cookies.set('setCountYqb', s)
export const getCountYqb = () => Cookies.get('setCountYqb')

const SOBEIRUI_AUTH_KEY = 'benlai_h5_sobeirui'
export const getSobeiruiAuth = () => !!Cookies.get(SOBEIRUI_AUTH_KEY)
export const setSobeiruiAuth = () => Cookies.set(SOBEIRUI_AUTH_KEY, '1')

const SINOPEC_AUTH_KEY = 'benlai_h5_sinoepc'
export const getSinoepcAuth = () => !!Cookies.get(SINOPEC_AUTH_KEY)
export const setSinoepcAuth = () => Cookies.set(SINOPEC_AUTH_KEY, '1', cookieOption)

const SINOPEC_INFO = 'benlai_h5_sinoepc_info'
export const getSinoepcInfo = () => Cookies.get(SINOPEC_INFO)
export const setSinoepcInfo = (info: string) => Cookies.set(SINOPEC_INFO, info)

const PLATFORM_AUTH_KEY = 'benlai_h5_'
export const getPlatformAuth = (platform: string) => !!Cookies.get(PLATFORM_AUTH_KEY + platform)
export const setPlatformAuth = (platform: string) => Cookies.set(PLATFORM_AUTH_KEY + platform, '1', cookieOption)

const LIANGCHU_AUTH_KEY = 'benlai_h5_liangchu'
export const getLiangChuAuth = () => !!Cookies.get(LIANGCHU_AUTH_KEY)
export const setLiangChuAuth = () => Cookies.set(LIANGCHU_AUTH_KEY, '1', cookieOption)

const UNIFY_CHANNEL_KEY = 'benlai_h5_unify_channel'
export const getUnifyChannel = () => Cookies.get(UNIFY_CHANNEL_KEY)
export const setUnifyChannel = (value: string) => Cookies.set(UNIFY_CHANNEL_KEY, value)
export const removeUnifyChannel = () => Cookies.remove(ABC_USER, cookieOption)

const MEICAN_AUTH_KEY = 'benlai_h5_maican'
export const getMeicanAuth = () => !!Cookies.get(MEICAN_AUTH_KEY)
export const setMeicanAuth = () => Cookies.set(MEICAN_AUTH_KEY, '1')

const THIRD_UNION_AUTH_KEY = 'THIRD_UNION_AUTH_KEY'
export const getUnionAuth = () => !!Cookies.get(THIRD_UNION_AUTH_KEY)
export const setUnionAuth = () => Cookies.set(THIRD_UNION_AUTH_KEY, '1')

const GAT_ID = 'benlai_h5_guanaitong'
export const getGATId = () => Cookies.get(GAT_ID)
export const setGATId = (user: string) => Cookies.set(GAT_ID, user, cookieOption)

const GAT_USER_ID = 'benlai_h5_guanaitonguserid'
export const getGATUserId = () => Cookies.get(GAT_USER_ID)
export const setGATUserId = (user: string) => Cookies.set(GAT_USER_ID, user, cookieOption)

const CCBNJ_INFO = 'benlai_h5_ccbnj_info'
export const getCCBNJInfo = () => Cookies.get(CCBNJ_INFO)
export const setCCBNJInfo = (info: string) => Cookies.set(CCBNJ_INFO, info, cookieOption)

export const setCCBNJcampusId = (info: any) => { // 建行生活支付的时候用，因为尤祺说同意用户不同园区没办发区分只能前端记录
  Cookies.set('ccbCorpId', info.corpId, cookieOption)
  Cookies.set('ccbCampusId', info.campusId, cookieOption)
}

const XFT_DATA = 'benlai_h5_xct_data'
export const getXFTData = () => Cookies.get(XFT_DATA)
export const setXFTData = (user: string) => Cookies.set(XFT_DATA, user, cookieOption)

const XFT_SIGN = 'benlai_h5_xct_sign'
export const getXFTSign = () => Cookies.get(XFT_SIGN)
export const setXFTSign = (user: string) => Cookies.set(XFT_SIGN, user, cookieOption)
