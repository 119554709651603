import to from 'await-to-js'
import axios from 'axios'
import qs from 'qs'
import configs from '@/configs'
import cookies from 'js-cookie'
import uuidv4 from 'uuid/v4'
import md5 from 'js-md5'
import getCurrentQuery from '@/utils/querystrings'
import { isWechat, inBenlaiApp, bridge, dsBridge } from '@/utils/env'

var objectSort = require('object-sort')

axios.defaults.withCredentials = true

const env: any = {
  webId: 1,
  source: 2,
  localcity: 2,
  areaId: 0,
  deviceId: '',
  channel: '',
  version: '',
  shareCode: ''
}

if (inBenlaiApp || isWechat) {
  const webId = getCurrentQuery('webId')
  const localcity = getCurrentQuery('localcity')
  const areaId = getCurrentQuery('areaId')
  // const version = getCurrentQuery('version')
  // const channel = getCurrentQuery('channel')
  let uuk = getCurrentQuery('deviceId') || cookies.get('uuk') || ''
  if (!uuk) {
    uuk = uuidv4()
    cookies.set('uuk', uuk, {
      domain: '.benlai.com'
    })
  }
  env.deviceId = uuk
  env.webId = webId ? parseInt(webId, 10) : 1
  env.localcity = localcity ? parseInt(localcity, 10) : 2
  env.areaId = areaId ? parseInt(areaId, 10) : 2
  env.shareCode = getCurrentQuery('shareCode') || cookies.get('ShareCode') || ''
  env.storeCode = getCurrentQuery('storeCode') || sessionStorage.getItem('storeCode') || ''
  cookies.set('ShareCode', env.shareCode, {
    domain: '.benlai.com',
    expires: 1 / 24
  })
  // env.version = version
  // env.channel = channel
} else {
  let uuk = cookies.get('uuk') || ''
  if (!uuk) {
    uuk = uuidv4()
    cookies.set('uuk', uuk, {
      domain: '.benlai.com'
    })
  }
  env.deviceId = uuk
  env.webId = cookies.get('WebSiteSysNo') ? cookies.get('WebSiteSysNo') : 1
  env.localcity = cookies.get('DeliverySysNo') ? cookies.get('DeliverySysNo') : 2
  env.areaId = cookies.get('DistrictSysNo') ? cookies.get('DistrictSysNo') : 0
  env.shareCode = getCurrentQuery('shareCode') || cookies.get('ShareCode') || ''
  env.storeCode = getCurrentQuery('storeCode') || sessionStorage.getItem('storeCode') || ''
  cookies.set('ShareCode', env.shareCode, {
    domain: '.benlai.com',
    expires: 1 / 24
  })
}

let tokenRequestPromise: Promise<any> | null = null
let tokenRegisterPromise: Promise<any> | null = null
let loginRequestPromise: Promise<any> | null = null
let authorizationRequestPromise: Promise<any> | null = null
// const smsRequestPromise: Promise<any> | null = null
const requestPromises: any = []

interface ServerResponse {
  error: string | undefined
  code: number
  value: any
}

const defaultOptions: any = {
  method: 'GET',
  responseType: 'json',
  responseEncoding: 'utf8',
  contentType: 'application/json; charset=utf-8',
  isRedirect: false,
  timeout: 10000,
  skipAuth: false
}

async function request (options: any) {
  let op = Object.assign({}, defaultOptions, options)

  const type = op.type
  const method = resolveMethod(op.method)
  const data = resolveParams(op.data)
  const headers = await resolveHeader(op)
  let url = resolveUrl(op.url, type)

  if (!url) {
    throw new Error('request url is not defined')
  }
  op = {
    url,
    method,
    headers
  }
  if (op.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    op.data = qs.stringify(data)
    url = addCommonQueryString(url)
    url = signQueryString(url, {})
  } else if (method === 'GET') {
    // op.params = {
    //   ...data,
    //   t: new Date().getTime()
    // }
    url = addCommonQueryString(url)
    // debugger
    url = signQueryString(url, {
      ...data
    })
    // debugger
    // op.paramsSerializer = (params: any) => {
    //   return qs.stringify(params, { indices: false })
    // }
  } else {
    op.data = data
    url = addCommonQueryString(url)
    url = signQueryString(url, {})
  }
  op.url = url
  const promiseKey: string = JSON.stringify({ method, url, data, headers })
  let promise: Promise<ServerResponse> | null | undefined = requestPromises[promiseKey]
  if (promise) {
    return promise
  }

  promise = new Promise<ServerResponse>(async (resolve, reject) => {
    const [err, res] = await to<any>(axios.request(op))
    requestPromises[promiseKey] = undefined
    if (err) {
      reject(err)
      return
    }
    let resJson: ServerResponse = res.data
    try {
      if (resJson && resJson.value && resJson.value.customerId && window.setArmsUserName) {
        window.setArmsUserName(resJson.value.customerId)
      }
    } catch (armsErr) {
      console.log('setArmsNameError>>', armsErr)
    }
    const resCode: number = resJson.code !== undefined ? resJson.code : Number(resJson.error)
    if (resCode === 0) {
      resolve(resJson)
      return
    }
    // if (resCode === 1004) {
    //   await userModule.initToken()
    //   resJson = await request({ ...options, reToken: (options.reToken || 0) + 1 })
    //   resolve(resJson)
    //   return
    // }
    if (resCode === 101 && !inBenlaiApp) {
      await requestValid()
      resJson = await request({ ...options, reToken: (options.reToken || 0) + 1 })
      resolve(resJson)
      return
    }
    if (resCode === 101 && inBenlaiApp) {
      await requestTokenFromNative()
      resJson = await request({ ...options, reToken: (options.reToken || 0) + 1 })
      resolve(resJson)
      return
    }
    if (resCode === 100 && !inBenlaiApp) {
      let loginUrl = `${configs.passportRoot}sms`
      history.pushState(null, '', location.href)
      location.href = loginUrl
      return
    }
    if (resCode === 100 && inBenlaiApp) {
      bridge.startLoginAction()
      return
    }
    if (resCode === 1006) {
      if (options.skipAuth) {
        resolve(resJson)
        return
      }
      if (location.pathname === '/auth') {
        return
      }
      if (options.isRedirect) {
        location.href = '/auth'
        return
      }
      const to = `${location.pathname}${location.search}`
      location.replace(`/auth?to=${to}`)
      return
    }
    reject(resJson)
  })
  requestPromises[promiseKey] = promise
  return promise
}

function resolveParams (data = {}) {
  const result: any = Object.assign({}, data)
  const source = sessionStorage.getItem('source') || ''
  const storeCode = sessionStorage.getItem('storeCode') || ''
  if (env.version) {
    result.version = env.version
  }
  if (env.channel) {
    result.channel = env.channel
  }
  if (env.shareCode) {
    result.invitationCode = env.shareCode
  }
  // result.version = configs.version
  result.deviceId = env.deviceId
  result.webId = env.webId
  result.localcity = env.localcity
  result.areaId = env.areaId || 0
  // result.channel = env.channel
  result.source = source ? parseInt(source) : 2
  if (parseInt(source) === 15) {
    result.storeCode = storeCode
  }
  return result
}
function resolveUrl (url: string | undefined, type: string | undefined) {
  let rootUrl = ''
  switch (type) {
    case 'apiv5':
      rootUrl = configs.appApiRoot
      break
    case 'unify':
      rootUrl = configs.unifyRoot
      break
    case 'amap':
      rootUrl = ''
      break
    default:
      rootUrl = configs.passportApiRoot
  }
  if (!url) {
    return ''
  }
  return /^http(s?):/.test(url) ? url : `${rootUrl}${url}`
  // return addCommonQueryString(absUrl)
}

function addCommonQueryString (url: string) {
  let query: any = {}
  const [path = '', queryString = ''] = url.split('?')
  if (queryString) {
    queryString.split('&').forEach((q) => {
      const [key = '', value = ''] = q.split('=')
      query = {
        ...query,
        [key]: value
      }
    })
  }
  const source = sessionStorage.getItem('source') || ''
  const storeCode = sessionStorage.getItem('storeCode') || ''
  query.deviceId = env.deviceId
  // query.version = configs.version
  if (env.version) {
    query.version = env.version
  }
  if (env.channel) {
    query.channel = env.channel
  }
  query.webId = env.webId
  query.localcity = env.localcity
  query.areaId = env.areaId || 0
  // query.channel = env.channel
  query.source = source ? parseInt(source) : 2
  if (parseInt(source) === 15) {
    query.storeCode = storeCode
  }
  query.t = new Date().getTime()

  const str = Object.keys(query).map((key) => {
    return `${key}=${query[key]}`
  }).join('&')
  return `${path}?${str}`
}

function signQueryString (url: string, data: any) {
  let query: any = {}
  const [path = '', queryString = ''] = url.split('?')
  if (queryString) {
    queryString.split('&').forEach((q) => {
      const [key = '', value = ''] = q.split('=')
      query = {
        ...query,
        [key]: value
      }
    })
  }
  query = objectSort({
    ...query,
    ...(data || {})
  })
  const str = Object.keys(query).map((key) => {
    return `${key}=${query[key]}`
  }).join('&')

  const sign = md5(`nichousha${str}chounizadi`)

  // console.log(`nichousha${str}chounizadi`, sign)
  return `${path}?${str}&sign=${sign}`
}

function resolveMethod (method: string | undefined) {
  if (!method) {
    return 'GET'
  }
  return method.toUpperCase()
}

async function getAuthorization (headerAuthorization: string = '') {
  if (headerAuthorization) {
    return headerAuthorization
  }
  if (inBenlaiApp) {
    return env.token
  }
  // try {
  // let token = userModule.token
  // if (!token) {
  //   await userModule.initToken()
  //   token = userModule.token
  // }
  // return token
  return ''
  // } catch (err) {
  //   // throw err
  // }
}

async function resolveHeader (options: any = {}) {
  const result = Object.assign({}, options.headers)
  try {
    // contenttype
    result['Content-Type'] = result['Content-Type'] ||
      options.contentType ||
      defaultOptions.contentType
    // Authorization
    const auth = await getAuthorization(result.Authorization)
    if (auth) {
      result.Authorization = auth
    }
    // uuk
    // const uuk = userModule.uuk
    // if (uuk) {
    //   result.UUK = uuk
    // }
    return result
  } catch (err) {
    throw err
  }
}

async function requestValid () {
  if (tokenRequestPromise) {
    return tokenRequestPromise
  }
  tokenRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: `${configs.passportApiRoot}valid`,
        data: {
          source: 2
        }
      })
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      tokenRequestPromise = null
    }
  })
  return tokenRequestPromise
}

async function requestOTCToken (otc: string = '', deviceId: string = '') { // 同步otc
  if (tokenRequestPromise) {
    return tokenRequestPromise
  }
  tokenRequestPromise = new Promise(async (resolve, reject) => {
    try {
      await httpHelper.post({
        url: `${configs.passportApiRoot}valid`,
        contentType: 'application/json',
        data: {
          otc,
          deviceId,
          source: 2
        }
      })
      resolve('')
    } catch (err) {
      reject(err)
    } finally {
      tokenRequestPromise = null
    }
  })
  return tokenRequestPromise
}

async function requestToken (data: any = {}) {
  if (tokenRequestPromise) {
    return tokenRequestPromise
  }
  tokenRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: 'Token',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          grant_type: 'client_credentials',
          ...data
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      // setStorageAuth(res)
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      tokenRequestPromise = null
    }
  })
  return tokenRequestPromise
}
async function requestThirdToken (data: any = {}) {
  if (tokenRequestPromise) {
    return tokenRequestPromise
  }
  tokenRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: 'authorize/thirdsms',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          // grant_type: 'client_credentials',
          ...data
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      // setStorageAuth(res)
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      tokenRequestPromise = null
    }
  })
  return tokenRequestPromise
}

async function requestUnionSMSToken (data: any = {}) {
  if (tokenRequestPromise) {
    return tokenRequestPromise
  }
  tokenRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: 'authorize/thirdCommonSmsLogin',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          // grant_type: 'client_credentials',
          ...data
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      // setStorageAuth(res)
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      tokenRequestPromise = null
    }
  })
  return tokenRequestPromise
}
async function requestUnionLoginToken (data: any = {}) {
  if (tokenRequestPromise) {
    return tokenRequestPromise
  }
  tokenRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: 'authorize/thirdUnionLogin',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          // grant_type: 'client_credentials',
          ...data
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      // setStorageAuth(res)
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      tokenRequestPromise = null
    }
  })
  return tokenRequestPromise
}

async function requestLogin (mobile: string, pwd: string) {
  if (loginRequestPromise) {
    return loginRequestPromise
  }
  loginRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: 'Token',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          grant_type: 'bu_pwd',
          account: mobile,
          pwd
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      loginRequestPromise = null
    }
  })
  return loginRequestPromise
}

async function requestSMS (mobile: string, code: string, vi: string) {
  if (loginRequestPromise) {
    return loginRequestPromise
  }
  loginRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: 'Token',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          grant_type: 'bu_quick',
          phone: mobile,
          code
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      // setStorageAuth(res)
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      loginRequestPromise = null
    }
  })
  return loginRequestPromise
}

async function requestRegister (mobile: string, code: string, pwd: string) {
  if (tokenRegisterPromise) {
    return tokenRegisterPromise
  }
  tokenRegisterPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: 'pwd/register',
        // contentType: 'application/x-www-form-urlencoded',
        data: {
          account: mobile,
          code,
          pwd
        }
      })
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      tokenRegisterPromise = null
    }
  })
  return tokenRegisterPromise
}

async function getAuthLogin (data: any = {}, url: string) {
  if (authorizationRequestPromise) {
    return authorizationRequestPromise
  }
  authorizationRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        url: url,
        contentType: 'application/x-www-form-urlencoded',
        data: {
          ...data
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      authorizationRequestPromise = null
    }
  })
  return authorizationRequestPromise
}

async function requestForBack (data: any) {
  await httpHelper.post({
    url: 'backlogin',
    // contentType: 'application/x-www-form-urlencoded',
    data: {
      ...data
    }
  })
}

async function requestThirdConfig () {
  if (tokenRequestPromise) {
    return tokenRequestPromise
  }
  const redirect = getCurrentQuery('redirect')
  var identity = decodeURIComponent(redirect) || ''
  // debugger
  if (identity.indexOf('//h5.benlai') > -1 || identity.indexOf('//m.benlai') > -1) {
    identity = ''
  }
  console.log('test', identity)
  tokenRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.get({
        url: `ThirdLogin/Sdk`,
        data: {
          identity: identity
        }
      })
      resolve(res)
    } catch (err) {
      reject(err)
    } finally {
      tokenRequestPromise = null
    }
  })
  return tokenRequestPromise
}

async function cmbLogin (cryptType: string, cryptData: string) {
  if (loginRequestPromise) {
    return loginRequestPromise
  }
  loginRequestPromise = new Promise(async (resolve, reject) => {
    try {
      const res = await httpHelper.post({
        contentType: 'application/x-www-form-urlencoded',
        url: `authorize/cmbmini`,
        data: {
          source: 12,
          grant_type: 'Auth_CmbMini',
          cryptType,
          // cryptData: window.encodeURIComponent(cryptData),
          cryptData
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      console.log('>>>', res)
      resolve(res)
    } catch (err) {
      console.error(err)
      reject(err)
    } finally {
      loginRequestPromise = null
    }
  })
  return loginRequestPromise
}

async function checkUnionLoginStatus (params: {
  channelTag: string
  storeTag: string
}) {
  try {
    const res = await httpHelper.post({
      contentType: 'application/x-www-form-urlencoded',
      url: `authorize/thirdCommonCheckLogin`,
      data: params
    })
    return res
  } catch (err) {
    console.error(err)
    throw err
  } finally {
    loginRequestPromise = null
  }
}

async function requestTokenFromNative () {
  return new Promise(async (resolve, reject) => {
    try {
      dsBridge.call('getPublicParticipation', '', (res: any) => {
        const publicInfo = JSON.parse(res)
        console.log('toLowerCase2', publicInfo.token)
        const token = publicInfo.token.toLowerCase().substr(0, 6) === 'bearer' ? publicInfo.token : `Bearer ${publicInfo.token}`
        env.token = token
        env.deviceId = publicInfo.deviceId
        // console.log('>>>>>', token)
        resolve(token)
      })
    } catch (err) {
      reject(err)
    } finally {
      // tokenRequestPromise = null
    }
  })
  // return tokenRequestPromise
}

const httpHelper: any = {
  request,
  requestToken,
  requestThirdToken,
  requestOTCToken,
  requestRegister,
  requestLogin,
  requestSMS,
  getAuthLogin,
  requestForBack,
  requestThirdConfig,
  requestTokenFromNative,
  cmbLogin,
  checkUnionLoginStatus,
  requestUnionSMSToken,
  requestUnionLoginToken,
  mRoot: configs.mRoot,
  // refreshGlobal,
  get (options: object) {
    return request({ ...options, method: 'GET' })
  },
  put (options: object) {
    return request({ ...options, method: 'PUT' })
  },
  post (options: object) {
    return request({ ...options, method: 'POST' })
  },
  delete (options: object) {
    return request({ ...options, method: 'DELETE' })
  }
}

export default httpHelper
