
import httpHelper from '@/utils/request'

import Vue from 'vue'
import Cookies from 'universal-cookie'
import Toasted from 'vue-toasted'
import VuejsDialog from 'vuejs-dialog'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import getCurrentQuery from '@/utils/querystrings'
import { inBenlaiApp } from '@/utils/env'
import { setGoBackUrl } from '@/utils/gobackurl'
import '@/scss/base.scss'
import configs from '@/configs'

const cookies = new Cookies()
const ua = window.navigator.userAgent
const isABC = /Bankabc/gi.test(ua)
if (isABC) {
  // alert(1111)
  document.addEventListener('back', function (e) {
    // alert('自定义返回')
    setTimeout(() => {
      history.back()
    }, 0)
    e.preventDefault() // 阻止默认返回行为
  }, false)
}

console.log(location.href)
Vue.config.productionTip = false

Vue.use(Toasted, {
  position: 'bottom-center',
  duration: 1500
})

Vue.use(VuejsDialog)

let afterUrl = unescape(getCurrentQuery('redirect_uri') || getCurrentQuery('redirect') || '')
if (document.referrer.includes('zt.benlai.com')) {
  afterUrl = document.referrer
}
const source = getCurrentQuery('source')
if (source) {
  sessionStorage.setItem('source', source)
}
const storeCode = getCurrentQuery('storeCode') || ''
if (source) {
  sessionStorage.setItem('storeCode', storeCode)
}
const redirectSite = getCurrentQuery('redirect_site')
if (redirectSite) {
  localStorage.setItem('redirectSite', redirectSite)
}

// async function loadOtc () {
//   await httpHelper.requestOTCToken(otc)
// }
// const otc = getCurrentQuery('otc') || ''
// console.log('otc数据是：', otc)
// if (otc && otc.length > 0) {
//   loadOtc()
// }
setGoBackUrl(afterUrl, source, storeCode)
// Vue.mixin(GlobalMixin)

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title || '本来生活-授权中心'
  }
})

declare module 'vue/types/vue' {
  interface Vue {
    $dialog: any,
  }
}

function createVueRoot () {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
const w = window as any
w.appLoginCallBack = async (token: string) => {
  console.log('toLowerCase1', token)
  if (!token) {
    return
  }
  const auth = token.toLowerCase().indexOf('bearer') === 0 ? token : 'Bearer ' + token
  await httpHelper.get({
    url: 'IAccount/CheckLogin',
    type: 'apiv5',
    headers: {
      Authorization: auth
    }
  })
  await httpHelper.requestTokenFromNative()
}
async function initApp () {
  // otc更新token
  const otc = getCurrentQuery('otc') || ''
  const deviceId = getCurrentQuery('deviceId') || ''
  console.log('otc是：', otc)

  if (otc && otc.length > 0) {
    await httpHelper.requestOTCToken(otc, deviceId)
  }

  if (!inBenlaiApp) {
    createVueRoot()
    return
  }

  const tokenObj = w.tokenObj
  const params = tokenObj ? JSON.parse(tokenObj.tokenJson()) : {}
  await w.appLoginCallBack(params.token)
  createVueRoot()
}
initApp()

const getScript = function (url: string, callback: Function) {
  var head = document.getElementsByTagName('head')[0] as any
  var js = document.createElement('script') as any

  js.setAttribute('type', 'text/javascript')
  js.setAttribute('src', url)

  head.appendChild(js)

  // 执行回调
  var callbackFn = function () {
    if (typeof callback === 'function') {
      callback()
    }
  }

  if (document.all) { // IE
    js.onreadystatechange = function () {
      if (js.readyState === 'loaded' || js.readyState === 'complete') {
        callbackFn()
      }
    }
  } else {
    js.onload = function () {
      callbackFn()
    }
  }
}

getScript(`${configs.appApiRoot}AppSupport/WebEnvConfig?deviceId=11`, function () {
  const w = window as {[index:string]:any}
  const benlaiEnv = w.benlaiEnv
  function parseSearch (search: string) {
    const result:{[index: string]: any} = {}
    if (search.indexOf('?') !== -1) {
      const str = search.substr(1)
      const strs = str.split('&')
      for (const pair of strs) {
        result[pair.split('=')[0]] = unescape(pair.split('=')[1])
      }
    }
    return result
  }
  const queryStrings = parseSearch(location.search)

  function uuid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }
  let saUrl = 'https://sensorsdata.benlai.com/sa?project=BL_Traffic'
  switch (benlaiEnv) {
    case 'branch':
    case 'trunk':
      saUrl = 'https://sensorsdata.benlai.com/sa?project=BL_Traffic_test'
  }
  // debugger
  (function (para) {
    var p = para.sdk_url; var n = para.name; var d = document; var s = 'script'; var x = null; var y = null
    if (typeof (w['sensorsDataAnalytic201505']) !== 'undefined') {
      return false
    }
    w['sensorsDataAnalytic201505'] = n
    w[n] = w[n] || function (a: string) { return function () { (w[n]._q = w[n]._q || []).push([a, arguments]) } }
    var ifs = ['track', 'quick', 'register', 'registerPage', 'registerOnce', 'trackSignup', 'trackAbtest', 'setProfile', 'setOnceProfile', 'appendProfile', 'incrementProfile', 'deleteProfile', 'unsetProfile', 'identify', 'login', 'logout', 'trackLink', 'clearAllRegister', 'getAppStatus']
    for (var i = 0; i < ifs.length; i++) {
      w[n][ifs[i]] = w[n].call(null, ifs[i])
    }
    // debugger
    if (!w[n]._t) {
      x = d.createElement(s) as any
      y = d.getElementsByTagName(s)[0] as any
      x.async = 1
      x.src = p
      x.setAttribute('charset', 'UTF-8')
      w[n].para = para
      if (y.parentNode) {
        y.parentNode.insertBefore(x, y)
      }
    }
  })({
    sdk_url: 'https://image.benlailife.com/FrontEnd/bfs/sensorsdata_v1.18.6.min.js',
    name: 'sensors',
    server_url: saUrl,
    heatmap: { scroll_notice_map: 'not_collect' },
    is_track_single_page: true,
    cross_subdomain: true,
    use_client_time: true,
    send_type: 'beacon',
    app_js_bridge: true
  })

  const sensors = w.sensors

  const websiteId = cookies.get('WebSiteSysNo') ? parseInt(cookies.get('WebSiteSysNo')) : 1
  const frontCityId = cookies.get('DeliverySysNo') ? parseInt(cookies.get('DeliverySysNo')) : 2
  sensors.registerPage({
    site_id: queryStrings.source === '5' ? 16 : 11,
    site_name: queryStrings.source === '5' ? '小程序' : 'H5',
    session_id: queryStrings.sessionId || uuid() + '_' + new Date().getTime(),

    website_id: queryStrings.webId ? parseInt(queryStrings.webId, 10) : websiteId,
    front_city_id: queryStrings.localcity ? parseInt(queryStrings.localcity, 10) : frontCityId,
    front_province_id: queryStrings.provinceId ? parseInt(queryStrings.provinceId, 10) : 0,

    data_environment: benlaiEnv
  })
  if (queryStrings.distinctId) {
    sensors.identify(queryStrings.distinctId, true)
  }
  sensors.quick('autoTrack')
})
